import {FC, useEffect} from 'react'
import clsx from 'clsx'
import {Link, NavLink} from 'react-router-dom'
import {useLocation} from 'react-router'
import {checkIsActive, KTIcon, WithChildren} from '../../../helpers'
import {useMenu} from "./MenuContext";

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
  isActive?: boolean
}

const AsideMenuItem: FC<Props & WithChildren> = ({
                                                   children,
                                                   to,
                                                   title,
                                                   icon,
                                                   fontIcon,
                                                   hasBullet = false,
                                                   isActive: propIsActive = false,
                                                 }) => {
  const {pathname} = useLocation()
  const isActive = propIsActive || checkIsActive(pathname, to);
  // const {isSubmenuRendered} = useMenu();
  // if (isActive === undefined || !isActive) isActive = checkIsActive(pathname, to)
  // // const isActive = checkIsActive(pathname, to)
  // useEffect(() => {
  //   if (title === '충전 이력') {
  //     console.log('충전 이력 isActive', isActive)
  //   }
  // }, [isActive])
  //
  // useEffect(() => {
  //   console.log("isRender 충전 이력")
  // }, []);

  return (
    <div className='menu-item'>
      <Link className={clsx('menu-link without-sub', {active: isActive})} to={to}>
        {fontIcon ? (
            <i className={clsx('bi fs-3', fontIcon)}></i>

        ) : (
          hasBullet && <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}
        {icon && (
          <span className='menu-icon'>
            <KTIcon iconName={icon} className='fs-2'/>
          </span>
        )}
        <span className='menu-title'>{title}</span>
      </Link>
      {children}
    </div>
  );
}

export {AsideMenuItem}
