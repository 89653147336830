import React, {useEffect, useState} from 'react'
import clsx from 'clsx'
import {useLocation} from 'react-router'
import {checkIsActive, KTIcon, WithChildren} from '../../../helpers'
import {useMenu} from "./MenuContext";
import {useAuth} from "../../../../app/modules/auth";
import {NavLink} from 'react-router-dom';

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
  isActive?: boolean
}

const AsideMenuItemWithSub: React.FC<Props & WithChildren> = ({
                                                                children,
                                                                to,
                                                                title,
                                                                icon,
                                                                fontIcon,
                                                                hasBullet = false,
                                                                isActive: propIsActive = false
                                                              }) => {
  const {pathname} = useLocation()
  const {menu} = useAuth()
  const isActive = propIsActive || checkIsActive(pathname, to);
  const [isOpen, setIsOpen] = useState<boolean>(isActive);

  useEffect(() => {
    setIsOpen(isActive);
  }, [isActive]);

  const toggleSubMenu = () => {
    setIsOpen(!isOpen);
  };

  if (children) {
    return (
      <div className={clsx('menu-item', {'here show': isActive}, 'menu-accordion')}>
      <span
        className='menu-link'
        onClick={toggleSubMenu}
        style={{cursor: 'pointer'}}
        aria-haspopup='true'
        aria-expanded={isOpen}
      >
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}
        {icon && (
          <span className='menu-icon'>
            <KTIcon iconName={icon} className='fs-2'/>
          </span>
        )}
        {fontIcon && <i className={clsx('bi fs-3', fontIcon)}></i>}
        <span className='menu-title'>{title}</span>
        <span className='menu-arrow'></span>
      </span>
        <div className={clsx('menu-sub menu-sub-accordion', {'menu-active-bg': isActive, 'show': isOpen})}>
          {children}
        </div>
      </div>
      // <div
      //   className={clsx('menu-item', {'here show': isActive}, 'menu-accordion')}
      //   data-kt-menu-trigger='click'
      // >
      // <span className='menu-link'>
      //   {hasBullet && (
      //     <span className='menu-bullet'>
      //       <span className='bullet bullet-dot'></span>
      //     </span>
      //   )}
      //   {icon && (
      //     <span className='menu-icon'>
      //       <KTIcon iconName={icon} className='fs-2'/>
      //     </span>
      //   )}
      //   {fontIcon && <i className={clsx('bi fs-3', fontIcon)}></i>}
      //   <span className='menu-title'>{title}</span>
      //   <span className='menu-arrow'></span>
      // </span>
      //   <div className={clsx('menu-sub menu-sub-accordion', {'menu-active-bg': isActive})}>
      //     {children}
      //   </div>
      // </div>
    )
  } else {
    return (
      <>
      </>
    )
  }
}

export {AsideMenuItemWithSub}
