export function getCurrentUrl(pathname: string) {
  return pathname.split(/[?#]/)[0]
}

export function checkIsActive(pathname: string, url: string) {
  const current = getCurrentUrl(pathname)
  if (!current || !url) {
    console.warn("pathname", pathname, "url", url, "true")
    return false
  }

  if (current === url) {
    console.warn("pathname", pathname, "url", url, "true")
    return true
  }

  if (current.indexOf(url) > -1) {
    return true
  }

  return false
}
