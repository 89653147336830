/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, Navigate, useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import {login} from '../core/_requests'
import {useAuth} from '../core/Auth'
import {AuthModel} from "../core/_models";
import axios from "axios";

const loginSchema = Yup.object().shape({
  email: Yup.string()
    // .email('Wrong email format')
    .min(3, '최소 3글자 이상 입력하세요')
    .max(20, '20자를 넘을 수 없습니다.')
    .required('아이디를 입력하세요'),
  password: Yup.string()
    .min(3, '최소 3글자를 입력하세요')
    .max(20, '20자를 넘을 수 없습니다.')
    .required('비밀번호를 입력하세요'),
})

const initialValues = {
  email: '',
  password: '',
}

export function Login() {
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser, setMenu} = useAuth()
  const navigate = useNavigate() // navigate 훅 사용 추가
  // const initPermission = usePermission();

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const {data: auth} = await login(values.email, values.password)
        if (auth.resultCode === 1 && auth.apiToken) {
          const authModel: AuthModel = {
            apiToken: auth.apiToken,
            refreshToken: auth.refreshToken, // 필요 시 추가
          };
          saveAuth(authModel);
          // console.log("auth", auth);
          setCurrentUser(auth);

          // 메뉴 데이터 가져오기
          const menuResponse = await axios.post(
            `${process.env.REACT_APP_API_URL}/common/logInUserMenuList`,
            {userGroupId: auth.userGroupId, menuId: ""},
            {
              headers: {
                Authorization: `Bearer ${auth.apiToken}`,
                'Content-Type': 'application/json'
              }
            }
          );

          const permittedMenus = menuResponse.data.filter(menu => menu.useYn === 'Y');
          // console.log('permittedMenus', permittedMenus)
          setMenu(permittedMenus);
          navigate('/');
        } else {
          saveAuth(undefined);
          setStatus('로그인 정보를 다시 확인하세요');
        }
      } catch (error) {
        console.error(error);
        saveAuth(undefined);
        setStatus('로그인 정보를 다시 확인하세요');
      } finally {
        setSubmitting(false);
        setLoading(false);
      }
    },
  });

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>로그인</h1>
        <div className='text-gray-500 fw-semibold fs-6'></div>
      </div>
      {/* begin::Heading */}

      {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        <div className='mb-10 bg-light-info p-8 rounded'>
          <div className='text-info'>
            {/*  여기에 로그인 오류 데이터를 표시한다. */}
            로그인 정보를 입력하세요
          </div>
        </div>
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>아이디</label>
        <input
          placeholder=''
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0'>비밀번호</label>
        <input
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Wrapper */}
      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
        <div/>

        {/* begin::Link */}
        <Link to='/auth/forgot-password' className='link-primary'>
          {/*비밀번호를 잊으셨습니까 ?*/}
        </Link>
        {/* end::Link */}
      </div>
      {/* end::Wrapper */}

      {/* begin::Action */}
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>로그인</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              잠시만 기다려주세요...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}


    </form>
  )
}
