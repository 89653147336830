import {useEffect, useState} from 'react';
import ApexCharts from 'apexcharts'
import ReactApexChart from 'react-apexcharts'


const ChartNew = ({chartData, title}) => {
    const [chartOption, setChartOption] = useState(null);

    const [labels, setLabels] = useState(null);
    useEffect(() => {
        if (chartData) {
            const makeLabels = chartData.labels.map((label, i) => {
                return {
                    id        : i,
                    isDisabled: false,
                    name      : label,
                    color     : chartData.backgroundColor[i],
                    data      : chartData.data[i],
                }
            });
            setLabels(makeLabels);
        }
    }, [chartData]);

    useEffect(() => {
        if (labels) {
            const datasArr = [];
            const labelsArr = [];
            const colorsArr = [];

            labels.map(label => {
                if (!label.isDisabled) {
                    datasArr.push(label.data)
                    labelsArr.push(label.name)
                    colorsArr.push(label.color)
                }
            });

            const initOption = {
                chart      : {
                    type: 'donut',
                    id  : "chart-id"
                },
                dataLabels : {
                    enabled: false
                },
                legend     : {
                    show: false,
                },
                fill       : {
                    colors: ['#F44336', '#E91E63', '#9C27B0']
                },
                plotOptions: {
                    pie: {
                        donut: {
                            labels: {
                                show : true,
                                name : {
                                    show      : true,
                                    fontFamily: "Inter, sans-serif",
                                    offsetY   : 20,
                                },
                                total: {
                                    showAlways: true,
                                    show      : true,
                                    label     : chartData.unit,
                                    fontFamily: "Inter, sans-serif",
                                    fontSize  : '22px',

                                    formatter: function (w) {
                                        const sum = w.globals.seriesTotals.reduce((a, b) => {
                                            return a + b
                                        }, 0)
                                        // return `${sum}k`
                                        return `${sum}`
                                    },
                                },
                                value: {
                                    show      : true,
                                    showAlways: true,
                                    label     : "",
                                    fontFamily: "Inter, sans-serif",
                                    font      : 'bold 12px Inter, sans-serif',
                                    fontSize  : '22px',
                                    fontWeight: "bold",
                                    offsetY   : -20,
                                    formatter : function (value) {
                                        return value + "k"
                                    },
                                }
                            },
                        },
                    }
                }
            }

            setChartOption({
                series : datasArr,
                options: {
                    ...initOption,
                    labels: labelsArr,
                    fill  : {
                        colors: colorsArr
                    },
                }
            })
        }
    }, [labels]);


    const toggleData = (selected) => {
        const makeLabels = labels.map((label, i) => {
            if (selected.id === label.id) {
                label.isDisabled = !label.isDisabled
            }
            return label
        });
        setLabels(makeLabels)
    }


    if (!labels || !chartOption || !chartData) {
        return null;
    }
    return (
        <>
            <div className="relative bg-white rounded-xl shadow-xl p-4 w-full sm:w-[350px]"
                 style={{width: '', height: '280px'}}>
                <h5 className="text-md font-bold leading-none text-gray-900 pr-1">{title}</h5>

                <div className=' '>
                    <div className="flex ">
                        <div className="absolute w-full top-1/2 -translate-y-1/2 -left-8 mt-6">
                            <ReactApexChart options={chartOption.options} series={chartOption.series} type="donut"
                                            width={280} height={250}/>
                        </div>
                        <div className="absolute  top-1/2 -translate-y-1/2 right-8 mt-6">
                            {labels.map((label, i) => (
                                <div key={i} className={`leading-4  w-full  flex mb-2 text-right cursor-pointer`}
                                     onClick={() => toggleData(label)}>
                                    <div
                                        className={`${label.isDisabled ? 'opacity-30' : ''} w-5 h-2 mt-1.5 rounded-xl shadow-xl  align-middle`}
                                        style={{backgroundColor: label.color}}></div>
                                    <div className={`
								${label.isDisabled ? 'line-through	decoration-gray-400	decoration-2	' : ''}
								text-sm  align-middle text-slate-700  tracking-tight ml-4 w-4/5 truncate`}>{label.name}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ChartNew;
