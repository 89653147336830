// src/app/routes/ProtectedRoute.tsx

import React from 'react';
import {useAuth} from '../modules/auth';
import {Navigate, Outlet, useLocation} from "react-router-dom";
import { getAllPermittedPaths } from '../modules/auth/core/utils';

const ProtectedRoute =  ()=> {
  const {auth, menu} = useAuth();
  const location = useLocation();


  if (!auth?.apiToken) {
    return (
      <Navigate
        to="/login"
        state={{ from: location }}
        replace
      />
    );
  }

  // 모든 허용된 경로를 추출
  const permittedPaths = getAllPermittedPaths(menu);

  // 현재 경로
  const currentPath = location.pathname;

  // 현재 경로가 허용된 경로에 포함되어 있는지 확인
  const isPermitted = permittedPaths.some(path => {
    // 정확한 일치를 확인하거나, 필요에 따라 경로 전처리를 할 수 있습니다.
    return currentPath.startsWith(path);
  });

  if (!isPermitted) {
    // console.log("ProtectedRoute", isPermitted, currentPath)
    // console.log("auth", auth)
    // console.log("permittedPaths", permittedPaths)
    // 권한이 없는 페이지, Unauthorized 페이지로 리다이렉션
    return (
      <Navigate
        to="/"
        state={{ from: location }}
        replace
      />
    );
  }

  // 권한이 있는 페이지, 해당 컴포넌트 렌더링
  return <Outlet />;
};

export default ProtectedRoute;
