import { useEffect, useState } from 'react';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const optionsA = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
        x: {
            grid: {
                display: false,
                color: '#818cf8',
            },
            ticks: {
                color: '#64748b',
            },
            gridLines: {
                display: false
            },
            border: {
                display: true,
                color: 'black',
            },
        },
        y: {
            grid: {
                drawBorder: false,
                lineWidth: 0,
                display: false,
                color: '#818cf8',
            },
            ticks: {
                color: '#000',
                stepSize: 500
            },
            border: {
                display: false
            },
        },
    },
    plugins: {
        legend: {
            labels: {
                color: 'rgb(255, 99, 132)'
            },
            position: 'top',
        },
    },
    onClick: function (event, elements, chart) {
        const xAxis = chart.scales.x;
        const mouseX = event.native.clientX - chart.canvas.getBoundingClientRect().left;
        const index = xAxis.getValueForPixel(mouseX);

        if (index !== undefined && !isNaN(index)) {
            xAxis.ticks.color = (ctx) => ctx.index === index ? 'blue' : '#64748b';
            chart.update();
        }
    },
    hover: {
        onHover: function (event, chartElement) {
            const xAxis = chartElement.chart.scales.x;
            const mouseX = event.native.clientX - chartElement.chart.canvas.getBoundingClientRect().left;
            const index = xAxis.getValueForPixel(mouseX);

            if (index !== undefined && !isNaN(index)) {
                xAxis.ticks.color = (ctx) => ctx.index === index ? 'green' : '#64748b';
                chartElement.chart.update();
                event.native.target.style.cursor = 'pointer';
            } else {
                xAxis.ticks.color = '#64748b';
                chartElement.chart.update();
                event.native.target.style.cursor = 'default';
            }
        }
    }
};

const customPlugin = {
    id: 'customPlugin',
    beforeDraw: (chart, args, options) => {
        return true
    },
    // afterEvent: (chart, args, options) => {
    //     const event = args.event;
    //     const xAxis = chart.scales.x;
    //     const mouseX = event.x;
    //     const index = xAxis.getValueForPixel(mouseX);
    //
    //     if (event.type === 'mousemove') {
    //         // console.log(chart, args)
    //         xAxis.ticks.color = (ctx) => ctx.index === index ? 'green' : '#64748b';
    //         chart.render();
    //         event.native.target.style.cursor = index !== undefined ? 'pointer' : 'default';
    //     }
    //
    //     if (event.type === 'click' && index !== undefined) {
    //         xAxis.ticks.color = (ctx) => ctx.index === index ? 'blue' : '#64748b';
    //         setTimeout(() => chart.update(), 0);
    //         console.log(`Clicked label index: ${index}`);
    //     }
    //
    //
    // }
};

export default function Table({ data }) {
    const [chartData, setChartData] = useState({
        labels: [],
        datasets: []
    });

    useEffect(() => {
        if (data) {
            const labels = data.lebel.map(item => item);
            const fastCounts = data.datasets[0].data.map(item => parseInt(item, 10));
            const slowCounts = data.datasets[1].data.map(item => parseInt(item, 10));

            const datasets = [
                {
                    label: '급속',
                    data: fastCounts,
                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                },
                {
                    label: '완속',
                    data: slowCounts,
                    backgroundColor: 'rgba(53, 162, 235, 0.5)',
                }
            ];

            setChartData({ labels, datasets });
        }
    }, [data]);

    useEffect(() => {
        // console.log('updated chartData', chartData);
    }, [chartData]);

    if (!data) {
        return null;
    }

    return (
        <div className='map-wrapper px-4 w-full h-5/6 sm:w-[600px]' style={{}}>
            <div className='relative rounded-xl shadow-xl p-4 w-full bg-white' style={{ height: '280px' }}>
                <Bar options={optionsA} data={chartData}  />
            </div>
        </div>
    );
}
