import {createContext, Dispatch, FC, SetStateAction, useContext, useEffect, useRef, useState,} from 'react'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import {AuthModel, MenuItem, UserModel} from './_models'
import * as authHelper from './AuthHelpers'
import {getUserByToken, getManagerLogo} from './_requests'
import {WithChildren} from '../../../../_metronic/helpers'
import axios from "axios";


type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  logout: () => void
  menu: MenuItem[];
  setMenu: (menu: MenuItem[]) => void;
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {
  },
  currentUser: undefined,
  setCurrentUser: () => {
  },
  logout: () => {
  },
  menu: [],
  setMenu: () => {
  },
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({children}) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
  const [menu, setMenu] = useState<MenuItem[]>([]);

  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = () => {
    saveAuth(undefined)
    setCurrentUser(undefined)
    setMenu([]);
  }

  return (
    <AuthContext.Provider value={{auth, saveAuth, currentUser, setCurrentUser, logout, menu, setMenu}}>
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({children}) => {
  const {auth, logout, setCurrentUser, setMenu} = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)

  useEffect(() => {
    const requestUser = async (apiToken: string) => {
      try {
        if (!didRequest.current) {
          const {data} = await getUserByToken(apiToken);
          if (data) {
            const logoResponse = await getManagerLogo(data.vendorId, data.managerAccount || "", apiToken);
            if (logoResponse.data && logoResponse.data.managerLogo) {
              data.managerLogo = logoResponse.data.managerLogo;
            }
            setCurrentUser(data);

            // 메뉴 데이터 API로부터 가져오기
            const menuResponse = await axios.post<MenuItem[]>(
              `${process.env.REACT_APP_API_URL}/common/logInUserMenuList`,
              {userGroupId: data.userGroupId},
              {
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${apiToken}`,
                },
              }
            );

            const permittedMenus = menuResponse.data.filter(menu => menu.useYn === 'Y');
            setMenu(permittedMenus);
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (auth && auth.apiToken) {
      requestUser(auth.apiToken)
    } else {
      logout()
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen/> : <>{children}</>
};

const fetchMenu = async (userGroupId: string, token: string): Promise<MenuItem[]> => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/common/userMenuGroupList`,
      {userGroupId: userGroupId, menuId: ""},
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      }
    );
    const data = response.data;
    const menuSet = data.filter((menu: any) => menu.useYn === 'Y');
    return data.map((menu: any) => menu.menuId).sort().map((menuId: string) => {
      return menuSet.find((menu: any) => menu.menuId === menuId);
    }).filter(Boolean);
  } catch (error) {
    console.error('Menu fetching error:', error);
    return [];
  }
};


export {AuthProvider, AuthInit, useAuth}
