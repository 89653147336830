// src/app/modules/auth/core/utils.ts

import { MenuItem } from './_models';

export const getAllPermittedPaths = (menu: MenuItem[]): string[] => {
  let paths: string[] = [];

  menu.forEach(item => {
    if (item.menuUrl) {
      paths.push(item.menuUrl);
    }
    if (item.pages && item.pages.length > 0) {
      paths = paths.concat(getAllPermittedPaths(item.pages));
    }
  });

  return paths;
};
