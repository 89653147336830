import axios from "axios";
import { UserModel } from "./_models";

const API_URL = process.env.REACT_APP_API_URL;

export const GET_USER_BY_ACCESS_TOKEN_URL = `${API_URL}/verifyToken`;
export const GET_MANAGER_LOGO_URL = `${API_URL}/manager/getManagerLogo`;
export const LOGIN_URL = `${API_URL}/login`;
export const REGISTER_URL = `${API_URL}/register`;
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`;

export function login(email: string, password: string) {
  return axios.post<UserModel>(LOGIN_URL, {
    userId: email,
    userPassword: password,
  });
}

export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  });
}

export function requestPassword(email: string) {
  return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, {
    email,
  });
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESS_TOKEN_URL, {
    apiToken: token,
  });
}

export function getManagerLogo(vendorId: string, managerAccount: string, token: string) {
  return axios.post<{ managerLogo: string }>(
    GET_MANAGER_LOGO_URL,
    { vendorId: vendorId, managerAccount: managerAccount },
    {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

